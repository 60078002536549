// src/components/Sidebar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css'; // Create this file for Sidebar styles
import nexus_logo from '../../assets/logo192.png'; // Update the path accordingly
import react_logo from '../../assets/logo192_old.png'; // Update the path accordingly
import { Avatar } from 'primereact/avatar';
const Sidebar = () => {
  return (
    <>
      <nav className="sidebar bg-light2 relative">
        <div className="text-center p-2">
          <img src={nexus_logo} alt="hyper" height={40} />
        </div>
        <ul className="nav flex-column p-2">
          <li className="nav-item py-1 ">
            <NavLink className="nav-link hover:text-white" to="/welcome"><i className="pi pi-home pr-2" ></i> Welcome</NavLink>
          </li>
          {/* <li className="nav-item py-1 ">
            <NavLink className="nav-link hover:text-white" to="/templates"><i className="pi pi-th-large pr-2" ></i>Templates</NavLink>
          </li> */}
          <li className="nav-item py-1 ">
            <NavLink className="nav-link hover:text-white" to="/uploadFiles"><i className="pi pi-upload pr-2" ></i>Upload Files</NavLink>
          </li>
          <li className="nav-item py-1 ">
            <NavLink className="nav-link hover:text-white" to="/DownloadReports"><i className="pi pi-file pr-2" ></i>Download Reports</NavLink>
          </li>
        </ul>

        <footer className="nav-item py-1 pl-3 mx-2 absolute bottom-0 left-0 ">
          <NavLink className="nav-link pb-3 pl-1" to="/logout"><i className="pi pi-sign-out pr-2" ></i>Logout</NavLink>
          <div className='flex justify-content-start w-full align-content-center text-white pb-5'>
            <Avatar image={react_logo} size="normal" shape="circle" />
            <span className='pl-2'>User Name</span>
          </div>
        </footer>

      </nav>
    </>
  );
};

export default Sidebar;
