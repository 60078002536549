import React, { useState } from 'react';
import axios from 'axios';
import nexus_logo from '../assets/logo192Light.png'; // Update the path accordingly
import './Login.css';

import { InputText } from 'primereact/inputtext';
// import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

const Login = ({ setAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post('/api/login', { email, password });
      if (response.data.success) {
        setAuthenticated(true);
      } else {
        setErrorMsg('Invalid email or password');
      }
    } catch (error) {
      setErrorMsg('Login error. Please try again later.');
      console.error('Login error', error);
    }
  };

  return (
    // <div className='login_page d-flex flex-column justify-content-center align-items-center'>
    //   <div className='login_container d-flex flex-column justify-content-center align-items-center'>
    //     <img src={nexus_logo} alt="NexusIqSolutions" height={50} />
    //     <br />
    //     <div className="d-flex justify-content-between align-items-center w-100">
    //       <label htmlFor="email" className="input_text">Email</label>
    //       <input
    //         type="email"
    //         name="email"
    //         placeholder="Enter Email"
    //         className="input_element"
    //         autoFocus
    //         id="email"
    //         onKeyUp={handleInputChange}
    //       />
    //     </div>
    //     <br />
    //     <div className="d-flex justify-content-between align-items-center w-100">
    //       <label htmlFor="password" className="input_text">Password &nbsp;</label>
    //       <input
    //         type="password"
    //         name="password"
    //         placeholder="Enter Password"
    //         id="password"
    //         className="input_element"
    //         onKeyUp={handleInputChange}
    //       />
    //     </div>
    //     <br />
    //     <p id="error_msg">{errorMsg}</p>
    //     <input type="button" className="login_button" value="Login" onClick={handleLogin} />
    //   </div>
    // </div>


    <div className="flex align-items-center justify-content-center h-screen">
      <div className="p-4 w-full lg:w-6 xl:w-4">
        <div className="text-center mb-5">
          <img src={nexus_logo} alt="hyper" height={80} />
        </div>

        <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
        <InputText
          id="email"
          type="email"
          name="email"
          placeholder="Enter Email"
          className="w-full mb-3"
          autoFocus
          value={email}
          onKeyUp={handleInputChange}
          onChange={handleInputChange}
        />
        <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
        <InputText
          id="password"
          type='password'
          name="password"
          placeholder="Enter Password"
          className="w-12"
          value={password}
          onChange={handleInputChange}
          toggleMask
        />
        <p id="error_msg" className='py-2'>{errorMsg}</p>
        <Button label="Login" className="w-full button" onClick={handleLogin} />
      </div>
    </div>

  );
};

export default Login;
